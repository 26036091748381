import React from "react";
import { Button } from "rebass";

export default function ButtonComponent({ style, children, ...props }) {
  return (
    <Button
      backgroundColor="transparent"
      my={3}
      className={props.className || "btn"}
      style={{
        border: "2px solid #2b2173",
        borderRadius: 20,
        fontSize: 12,
        fontWeight: 700,
        cursor: "pointer",
        flexShrink: 0,
        transition: "all 100ms ease-out",
        letterSpacing: 1,
        ...style,
      }}
      color="#2b2173"
      {...props}
    >
      {children}
    </Button>
  );
}

import React from "react";
import { Heading } from "rebass";
import { useGlobalCartContext, useAmount } from "../hooks/useCart";

export default function Total() {
  const cart = useGlobalCartContext();

  const total = useAmount(cart);

  return <Heading fontSize={3}>Total invest: {total}€</Heading>;
}

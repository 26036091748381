import React from "react";
import { Box, Heading, Image } from "rebass";

const images = [
  "there-is-no-spoon.gif",
  "yoga-cat.gif",
  "cat-2.jpg",
  "bear.jpg"
];

export default function Loading({ heading }) {
  const image = images[Math.floor(Math.random() * images.length)];
  return (
    <Box>
      <Heading fontSize={3} pb={3}>
        {heading}
      </Heading>
      <Image
        src={"/images/" + image}
        sx={{
          maxWidth: 300,
          width: ["100%", "50%"],
          borderRadius: 8
        }}
      />
    </Box>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { Heading, Text, Box, Flex } from "rebass";
import Button from "./Button";
import Card from "./Card";
import { useGlobalCartContext } from "../hooks/useCart";
import useLocalStorage from "../hooks/useLocalStorage";
import JoinUrl from "./JoinUrl";

const weekdayFormatter = new Intl.DateTimeFormat("de-DE", {
  weekday: "short",
});

const dateFormatter = new Intl.DateTimeFormat("de-DE", {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
});

const timeFormatter = new Intl.DateTimeFormat("de-DE", {
  hour: "2-digit",
  minute: "2-digit",
});

const getMinutesLeft = (startTime) =>
  Math.floor((startTime - new Date()) / 1000 / 60);

export default function Event({ meeting, goToNextStep }) {
  // const regex = /Price:\s*([0-9,.]*)\s*€/;
  const timer = useRef(null);
  const price = meeting.agenda.split(":");
  const priceParsed =
    typeof price?.[1] === "string" ? parseFloat(price[1].replace(",", ".")) : 0;

  const startTime = new Date(meeting.start_time);

  const [minutesLeft, setMinutesLeft] = useState(getMinutesLeft(startTime));

  const [bookedMeetings] = useLocalStorage("bookedMeetings", "");

  const bookedMeetingsArray = bookedMeetings.split("|");
  // const bookedMeetingsArray = ["gS5BgLc/T4yDAYeKFjojCA=="];

  const isAlreadyBooked = bookedMeetingsArray.includes(meeting.uuid);

  const datePreformatted = dateFormatter ? startTime : null;

  const dateFormatted = datePreformatted
    ? dateFormatter.format(datePreformatted)
    : null;

  const weekdayFormatted = datePreformatted
    ? weekdayFormatter.format(datePreformatted)
    : null;

  const timeFormatted = datePreformatted
    ? timeFormatter.format(datePreformatted)
    : null;

  const cartItem = {
    id: meeting.uuid,
    joinUrl: meeting.join_url,
    amount: priceParsed,
    // amount: 0,
    title: meeting.topic,
    description: meeting.agenda,
    date: dateFormatted,
    time: timeFormatted,
  };

  const cart = useGlobalCartContext();
  const [added, setAdded] = useState(cart.inCart(cartItem));
  const bookingClosed = minutesLeft < 30;

  const toggleItem = () => {
    if (!added) {
      setAdded(true);
      cart.addCartItem([cartItem]);
      goToNextStep();
    } else {
      setAdded(false);
      cart.removeCartItems(cartItem);
    }
  };

  useEffect(() => {
    timer.current = setInterval(() => {
      setMinutesLeft(getMinutesLeft(startTime));
    }, 1000);
    return () => clearInterval(timer.current);
  }, [startTime]);

  return (
    <Card key={meeting.uuid}>
      <Flex mb={3} alignItems="center">
        <Text
          pt={2}
          mr={2}
          style={{
            padding: "0px",
            background: "rgb(172, 169, 201)",
            display: "inline-block",
            textTransform: "uppercase",
            color: "white",
            fontSize: 19,
            textAlign: "center",
            borderRadius: 4,
            width: 50,
            height: 50,
            lineHeight: "50px",
          }}
        >
          {weekdayFormatted}
        </Text>
        <Box>
          <Text color="#656565">
            {dateFormatted} – {timeFormatted}
          </Text>
        </Box>
      </Flex>
      <Heading
        color={bookingClosed ? "aaa" : "#1b1b1b"}
        fontSize={3}
        textTransform="none"
        letterSpacing="1px"
        lineHeight={1.4}
        mb={2}
      >
        {meeting.topic}
      </Heading>
      <Text pt={2}>{meeting.agenda}</Text>

      {isAlreadyBooked ? (
        <>
          <Text pt={4}>You booked this class:</Text>
          <JoinUrl joinUrl={meeting.join_url} />
        </>
      ) : bookingClosed ? (
        <>
          <Text pt={4}>
            Sorry! Sessions are bookable until 30 minutes before the beginning.
          </Text>
        </>
      ) : (
        <>
          <Box pt={4}>
            <Button
              className={`book-btn${added ? " is--active" : ""}`}
              backgroundColor="transparent"
              my={0}
              onClick={toggleItem}
              color="#2b2173"
            >
              {added ? "✓ chosen" : "choose"}
            </Button>
          </Box>
        </>
      )}
    </Card>
  );
}

import React, { useEffect } from "react";
import "./App.css";
import BookEvents from "./components/BookEvents";

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function App() {
  // console.log("App");
  useEffect(() => {
    if (inIframe()) {
      document.domain = "luisadoerstel.com";
    }
  }, []);
  return <BookEvents />;
}

export default App;

import React, { useState, createContext, useContext } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

const GlobalCartContext = createContext();

export const useGlobalCartContext = () => useContext(GlobalCartContext);

export const useAmount = (cart) => {
  let total = null;
  if (cart?.cartItems && cart.cartItems.length > 0) {
    total = 0;
    cart.cartItems.map((cartItem) => {
      return (total += cartItem.amount);
    });
  }
  return total;
};

const GlobalCartContextProvider = (props) => {
  const [cartItems, setCartItems] = useState([]);
  const [checkedOut, setCheckedOut] = useState(false);
  const [email, setEmail] = useState(null);
  const [bookedMeetings, setBookedMeetings] = useLocalStorage(
    "bookedMeetings",
    false
  );

  const addCartItem = (cartItemAdd) => {
    const updatedCartItems = cartItems.concat(cartItemAdd);
    setCartItems(updatedCartItems);
    return updatedCartItems;
  };

  const removeCartItems = (cartItemRemove) => {
    const updatedCartItems = cartItems.filter((cartItem) => {
      // console.log("remove", cartItem, cartItemRemove);
      return cartItem.id !== cartItemRemove.id;
    });
    setCartItems(updatedCartItems);
  };

  const clearCart = (cartItemRemove) => {
    setCartItems([]);
  };

  const inCart = (cartItemCheck) => {
    return (
      cartItems.filter((cartItem) => cartItem.id === cartItemCheck.id).length >
      0
    );
  };

  const updateBookedMeetings = (items) => {
    const bookedMeetingsArray = bookedMeetings ? bookedMeetings.split("|") : [];
    const newlyBookedMeetingsArray = [];
    items &&
      items.map((item) => {
        newlyBookedMeetingsArray.push(item.id);
        return newlyBookedMeetingsArray;
      });
    const updatedBookedMeetings =
      newlyBookedMeetingsArray?.length > 0
        ? bookedMeetingsArray.concat(newlyBookedMeetingsArray)
        : bookedMeetingsArray;
    const updatedBookedMeetingsString = updatedBookedMeetings.join("|");
    setBookedMeetings(updatedBookedMeetingsString);
    return updatedBookedMeetingsString;
  };

  return (
    <GlobalCartContext.Provider
      value={{
        cartItems,
        addCartItem,
        removeCartItems,
        checkedOut,
        setCheckedOut,
        updateBookedMeetings,
        inCart,
        clearCart,
        email,
        setEmail,
      }}
    >
      {props.children}
    </GlobalCartContext.Provider>
  );
};

export default GlobalCartContextProvider;

import React from "react";

import { Box, Flex, Link } from "rebass";
import { Input } from "@rebass/forms";

export default function JoinUrl({ joinUrl }) {
  return (
    <Box pt={2}>
      <Box>
        <Link href={joinUrl} target="_blank" color="#2b2173 !important">
          click to join class now
        </Link>
      </Box>
      <Flex pt={2} alignItems="center">
        <Input type="text" value={joinUrl} readOnly />
      </Flex>
    </Box>
  );
}

import React, { useEffect } from "react";
import { Box } from "rebass";
import Card from "./Card";
import CardList from "./CardList";
import { useGlobalCartContext, useAmount } from "../hooks/useCart";
import sendMail from "../sendMail";

export default function PaypalButtons() {
  const cart = useGlobalCartContext();
  const total = useAmount(cart);

  useEffect(() => {
    const renderButtons = () => {
      // sendMail({ cart });
      if (total > 0) {
        window.paypal
          .Buttons({
            style: {
              layout: "vertical",
              shape: "pill",
            },
            createOrder: function (data, actions) {
              // This function sets up the details of the transaction, including the amount and line item details.
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: "EUR",
                      value: total,
                    },
                  },
                ],
              });
            },
            onApprove: function (data, actions) {
              // This function captures the funds from the transaction.
              return actions.order.capture().then(function (details) {
                // This function shows a transaction success message to your buyer.
                cart.setCheckedOut(true);
                cart.updateBookedMeetings(cart.cartItems);
                sendMail({ cart });
              });
            },
          })
          .render("#paypal-button-container");
      } else {
        sendMail({ cart });
        cart.setCheckedOut(true);
        cart.updateBookedMeetings(cart.cartItems);
      }
    };

    const existingScript = document.getElementById("paypal-script");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_API_CLIENT_ID}&currency=EUR`;
      script.id = "paypal-script";
      script.onload = () => {
        renderButtons();
      };
      document.body.appendChild(script);
    } else {
      renderButtons();
    }
  }, [cart, total]);

  return (
    <CardList>
      <Card>
        <Box id="paypal-button-container" maxWidth={300}></Box>
      </Card>
    </CardList>
  );
}

export default function sendMail({ cart }) {
  fetch(`.netlify/functions/sendJoinLink`, {
    method: "POST",
    redirect: "follow",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({ cart }),
  })
    .then((res) => res.json())
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

import React, { useState } from "react"
import GlobalCartContextProvider from "../hooks/useCart"
import { Box } from "rebass"
import Checkout from "./Checkout"
import EventList from "./EventList"

export default function BookEvents() {
  const [step, setStep] = useState("choice")

  return (
    <GlobalCartContextProvider>
      <Box maxWidth="100%" overflow="hidden">
        {step === "choice" && <EventList setStep={setStep} />}
        {step === "checkout" && (
          <Box>
            <Checkout setStep={setStep} />
          </Box>
        )}
      </Box>
    </GlobalCartContextProvider>
  )
}

import { useState, useEffect } from "react";

export default function useEvents() {
  const [events, setEvents] = useState(null);

  useEffect(() => {
    fetch(`.netlify/functions/event`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        // setTimeout(() => {
        setEvents(response);
        // }, 1000);
      })
      .catch((error) => console.log(error));
  }, []);
  return events;
}

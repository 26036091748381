import React from "react";
import { Flex } from "rebass";
export default function Card({ children, props }) {
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      backgroundColor="#fff"
      p={4}
      m={3}
      maxWidth={500}
      width="calc(33.33% - 2 * 16px)"
      style={{
        borderRadius: 10,
        boxShadow: "5px 0px 20px rgba(0,0,0,0.05)",
        transition: "all 300ms ease-out",
        flexGrow: 1
      }}
      className="card"
      minWidth={300}
      color="#484848"
    >
      {children}
    </Flex>
  );
}

import React from "react";
import useEvents from "../hooks/useEvents";
import { useGlobalCartContext, useAmount } from "../hooks/useCart";

import { Box } from "rebass";
import Event from "./Event";
import CardList from "./CardList";
import Button from "./Button";
import Total from "./Total";
import Loading from "./Loading";

const compareDates = (a, b) => {
  if (new Date(a.start_time) < new Date(b.start_time)) {
    return -1;
  }
  if (Date(a.start_time) > Date(b.start_time)) {
    return 1;
  }
  return 0;
};

export default function EventList({ setStep }) {
  const events = useEvents();
  const cart = useGlobalCartContext();
  const amount = useAmount(cart);

  // filter old meetings
  const meetingsFiltered = events?.meetings
    ? events.meetings.filter(
        (meeting) =>
          new Date(meeting.start_time) > new Date() &&
          meeting?.agenda?.search("PRIVATE") < 0
      )
    : null;

  // sort meetings by date
  const meetings = meetingsFiltered
    ? meetingsFiltered.sort(compareDates)
    : null;

  // meetings && console.log("EventList", meetings)

  return (
    <Box>
      {meetings && (
        <>
          {amount !== null && (
            <>
              <Total />
              <Button className="btn" onClick={() => setStep("checkout")}>
                register and pay
              </Button>
            </>
          )}

          <CardList>
            {meetings &&
              meetings.map((meeting) => {
                // console.log("Event");
                return (
                  <Event
                    key={meeting.uuid}
                    meeting={meeting}
                    goToNextStep={() => setStep("checkout")}
                  />
                );
              })}
          </CardList>
        </>
      )}

      {events !== null && !events?.meetings && (
        <Loading heading="No classes found or something went totally wrong..." />
      )}
      {events === null && <Loading heading="hang in there..." />}

      {events && (
        <Box mt={3}>
          {amount !== null && (
            <>
              <Total />
              <Button className="btn" onClick={() => setStep("checkout")}>
                register and pay
              </Button>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

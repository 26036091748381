import React from "react";
import { Flex } from "rebass";

export default function CardList({ children }) {
  return (
    <Flex mx={-3} flexWrap="wrap">
      {children}
    </Flex>
  );
}

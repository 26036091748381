import React, { useRef, useState } from "react";
import { useGlobalCartContext } from "../hooks/useCart";
import { Box, Heading, Text } from "rebass";
import PaypalButtons from "./PaypalButtons";
import Total from "./Total";
import Card from "./Card";
import CardList from "./CardList";
import JoinUrl from "./JoinUrl";
import Button from "./Button";
import { Input } from "@rebass/forms";

export default function Checkout({ setStep }) {
  const cart = useGlobalCartContext();
  const mailInput = useRef();
  const [invalid, setInvalid] = useState(true);

  const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
  console.log(cart);

  return (
    <Box>
      {!cart.email ? (
        <Box>
          <Card>
            <Heading py={4}>Please enter your email</Heading>
            <Text>So you can get the join Links</Text>
            <Input
              my={20}
              type="text"
              ref={mailInput}
              placeholder="z. B. shavasana@yogamail.com"
              style={{ borderRadius: 100, padding: "10px 20px" }}
              onKeyUp={() => setInvalid(!regex.test(mailInput.current.value))}
            />
            <Button
              className={`book-btn is--active`}
              backgroundColor="transparent"
              my={0}
              borderRadius={"100px"}
              style={{
                opacity: invalid ? 0.5 : 1,
                pointerEvents: invalid ? "none" : "all",
              }}
              disabled={invalid}
              onClick={() => {
                cart.setEmail(mailInput.current.value);
              }}
              color="#2b2173"
            >
              next
            </Button>
          </Card>
        </Box>
      ) : cart.checkedOut ? (
        <Box>
          <Heading py={4}>
            Thank you for your investment{" "}
            <span role="img" aria-label="yoga">
              🧘
            </span>{" "}
            See you virtually on the matt!
          </Heading>
          <Text>Find your bookings below</Text>
          <Text pt={2} pb={4}>
            <strong>Attention!</strong> Please copy your individual zoom links
            before leaving!
          </Text>
          <CardList>
            {cart.cartItems.map((cartItem) => {
              return (
                <Card key={cartItem.id}>
                  <Heading fontSize={3}>{cartItem.title}</Heading>
                  <Text py={3}>{cartItem.description}</Text>
                  <JoinUrl joinUrl={cartItem.joinUrl} />
                </Card>
              );
            })}
          </CardList>
          <Box pb={3}>
            <Total />
          </Box>
        </Box>
      ) : (
        <Box>
          <Heading py={4}>Register and pay</Heading>
          <Box pb={3}>
            <Total />
          </Box>
          <PaypalButtons />
        </Box>
      )}
      <Button
        className="btn"
        onClick={() => {
          cart.clearCart();
          cart.setEmail(null);
          cart.setCheckedOut(false);
          setStep("choice");
        }}
      >
        back
      </Button>
    </Box>
  );
}
